import type {ReactElement} from 'react';
import {LayoutListingPost} from '@uxu/design-system/components/layout/layoutListingPost/layoutListingPost';
import {CrumbleMenu} from '@uxu/design-system/components/molecules/crumbleMenu';
import {useSeoConfig} from '@uxu/design-system/hooks/useSeoConfig';
import {footerConfig, headerMenuConfig} from '../config';

function Index(): ReactElement {
    const seo = useSeoConfig({});

    return (
        <LayoutListingPost
            footer={footerConfig}
            headerMenu={headerMenuConfig}
            seo={seo}
            topElement={(
                <>
                    <CrumbleMenu data={[{title: "home", href: "/"}]}/>
                    <h1 style={{width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>W przebudowie</h1>
                </>
            )}
        />
    );
}

export default Index;
